<template>
    <div>
        <b-col cols="12">
            <zw-input-group v-model="inputVal.title"
                            name="title"
                            :label-prefix="labelPrefix"
                            validate="required"
            ></zw-input-group>
        </b-col>
        <b-col cols="12">
            <zw-input-group v-model="inputVal.description"
                            name="description"
                            :label-prefix="labelPrefix"
                            validate="required"
            ></zw-input-group>
        </b-col>
        <b-col cols="12">
            <b-form-checkbox v-model="inputVal.triggerred_user">{{ $t(labelPrefix+'triggered_user') }}</b-form-checkbox>
            <zw-select-group v-model="inputVal.user_id"
                             :options="getUsersList()"
                             v-if="!inputVal.triggerred_user"
                             name="user"
                             :label-prefix="labelPrefix"
                             validate="required"
                             text-field="fullname"
                             value-field="id"
            ></zw-select-group>
        </b-col>
        <b-col cols="12">
            <zw-select-group v-model="inputVal.status"
                             :options="getTodoStatues()"
                             name="todo.status"
                             validate="required"
            ></zw-select-group>
        </b-col>
        <b-col cols="12">
            <zw-select-group v-model="inputVal.date"
                             :options="['today','tomorrow','next_week','next_month','next_year']"
                             name="todo.deadline"
                             validate="required"
            ></zw-select-group>
        </b-col>
        <b-col cols="12">
            <zw-select-group v-model="inputVal.action"
                             :options="getTodoActions()"
                             validate="required"
                             name="todo.action"
            ></zw-select-group>
        </b-col>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'ChangeStatusOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('CommonData', ['getEntityStatuses', 'getTodoStatues', 'getTodoActions']),
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>